import {
    createSlice,
    createAsyncThunk,
    createDraftSafeSelector,
} from '@reduxjs/toolkit';

const axios = require('axios');

const baseSlice = createSlice({
    name: 'base',
    initialState: {
      gamedata: [],
      tab: 'battle',
      nftdata: [],
      pubkey: null,
      fight_done: false,
      fights_data: [],
      fightsDataLoaded: false,
      fighting: false,
      leaderboard_data: [],
      leaderboardLoaded: false,
    },
    reducers: {
        savePubkey(state, action) {
            if (!state.pubkey) {
                if (action.payload) {
                    postUser(action.payload)
                }
            }
            state.pubkey = action.payload
        },
        addNFTdata(state, action) {
            state.nftdata.push(action.payload);
        },
        wipeNFTdata(state, action) {
            state.nftdata = []
        },
        saveTab(state, action) {
            state.tab = action.payload
        },
        fightDone(state, action) {
            state.fight_done = true
            state.fighting = false
            state.fightResults = action.payload
        },
        deleteResults(state, action) {
            state.fight_done = false
            state.fightResults = {}
        },
        saveFightsData(state, action) {
            state.fights_data = action.payload
            state.fightsDataLoaded = true
        },
        resetFightsData(state, action) {
            state.fights_data = []
            state.fightsDataLoaded = false
        },
        setFighting(state, action) {
            state.fighting = true
        },
        saveLeaderboardData(state, action) {
            state.leaderboard_data = action.payload
            state.leaderboardLoaded = true
        },
        resetLeaderboardData(state, action) {
            state.leaderboard_data = []
            state.leaderboardLoaded = false
        },
        pushGamedata(state, action) {
            state.gamedata.push(action.payload)
        },
    },
    extraReducers: {
    },
});
  
export const {
    addNFTdata,
    wipeNFTdata,
    savePubkey,
    saveTab,
    fightDone,
    deleteResults,
    saveFightsData,
    resetFightsData,
    setFighting,
    saveLeaderboardData,
    resetLeaderboardData,
    pushGamedata,
} = baseSlice.actions;
  
  export default baseSlice.reducer;
  

function postUser(pubkey) {
    axios.post('https://swars.builtwithdark.com/login', {
        user: pubkey
    })
}
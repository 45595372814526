import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import React, { useCallback } from 'react';
import * as metadata from "@metaplex-foundation/mpl-token-metadata";
import store from './store';

import { addNFTdata, wipeNFTdata, pushGamedata } from './baseReducer';

import { Button } from 'antd';

const axios = require('axios');

export function FindNFTsButton() {
    const { connection } = useConnection();
    const wallet = useWallet();
    const { publicKey, sendTransaction } = useWallet();

    const onClick = useCallback(async () => {
        store.dispatch(wipeNFTdata())
        const nfts = await metadata.Metadata.findDataByOwner(connection, publicKey);

        nfts.forEach(nft => {
            axios.get(nft.data.uri).then( res => {
                let NFTdata = res.data
                NFTdata.mint = nft.mint
                store.dispatch(addNFTdata(NFTdata))
                postData(res.data, nft.mint, publicKey.toString())
                fetchGamedata(nft.mint)
            })
        })

    }, [publicKey, sendTransaction, connection]);

    return (
        <Button className='spacer' type="primary" block onClick={onClick}>
        Load My NFTs
      </Button>
    );
};

function postData(data, mint, pubkey) {
    axios.post('https://swars.builtwithdark.com/post_item', {
        icon: mint,
        data: data,
        owner: pubkey
    })
}

function fetchGamedata(mint) {
    axios.post('https://swars.builtwithdark.com/IconGamedata', {
        icon: mint,
    }).then(res => {
        store.dispatch(pushGamedata(res.data))
    })
}
import { useSelector } from 'react-redux';

import { Row, Col, Divider } from 'antd';

import { Button } from 'antd';
import axios from 'axios';
import store from './store';

import { fightDone } from './baseReducer.js';

const style = { background: '#0092ff', padding: '8px 0' };

const whitelist_symbols = ['BAIL']

export function NFTs() {
    const nfts = useSelector((state) => state.base.nftdata)
    const pubkey = useSelector((state) => state.base.pubkey)

    let image_items = []
    
    nfts.forEach(nft => {
        if (whitelist_symbols.includes(nft.symbol)) image_items.push(<Col className="gutter-row" span={6}><ImageContainer nft={nft.mint} image={nft.image} valid={whitelist_symbols.includes(nft.symbol)} /></Col>);
    })

    return <div>
        <Divider orientation="left">My NFTs - {pubkey.slice(0,6) + '..' + pubkey.slice(pubkey.length-6,pubkey.length)}</Divider>
    <Row gutter={[16,24]}>{image_items}
    </Row>
    </div>
}

function ImageContainer(props) {
    const pubkey = useSelector((state) => state.base.pubkey)
    const gamedata = useSelector((state) => state.base.gamedata)
    
    let gamedate = {}
    gamedata.forEach(item => {
        if (props.nft == item.Icon) {
            gamedate = item
        }
    })

    const battleFunction = () => {
        axios.post('https://swars.builtwithdark.com/fight_random', {
            pubkey: pubkey,
            image: props.image,
        }).then(res => {
            store.dispatch(fightDone(res.data))
        })
    }

    console.log(gamedate)
    let testdate = new Date(gamedate.Last_Fought)
    console.log(testdate)
    let newdate = new Date()
    console.log(newdate)

    return <Col>
        <img className={'nft_image'} src={props.image} />
        {props.valid ? <Button type='primary' block onClick={battleFunction}>Battle Random</Button> : <Button danger block>Not Listed (click to fix)</Button>}
    </Col>
}
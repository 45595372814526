import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
    WalletDisconnectButton,
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';

import store from './store';
import { savePubkey, saveTab } from './baseReducer.js';

import { NFTs } from './NFTs'
import { FindNFTsButton } from './TXButton.js';

import { Layout, Menu, Breadcrumb } from 'antd';
import { Row, Col, Divider } from 'antd';
import { Button } from 'antd';
import { Table, Tag, Space } from 'antd';

import axios from 'axios';
import { saveFightsData, resetFightsData } from './baseReducer.js';

export function LastFights() {
    const fights_data = useSelector((state) => state.base.fights_data)
    const fightsDataLoaded = useSelector((state) => state.base.fightsDataLoaded)

    useEffect(() => {
        //
        return () => {
            store.dispatch(resetFightsData())
        }
    }, [])

    if (!fightsDataLoaded) {
        axios.get('https://swars.builtwithdark.com/last_fights').then(res => {
            store.dispatch(saveFightsData(res.data))
        })
    }

    const columns = [
        {
          title: 'Attacker',
          dataIndex: 'attacker',
          key: 'attacker',
        },
        {
          title: 'Attacker Points',
          dataIndex: 'atk_points',
          key: 'atk_points',
        },
        {
          title: 'Defender',
          dataIndex: 'defender',
          key: 'defender',
        },
        {
            title: 'Defender Points',
            dataIndex: 'def_points',
            key: 'def_points',
          },
      ];

      const data = []
      fights_data.forEach(fight => {
          data.push({
              attacker: fight.Attacker,
              atk_points: fight.AttackerPoints,
              defender: fight.Defender,
              def_points: fight.DefenderPoints
          })
      })

    return <Table columns={columns} dataSource={data} />
}
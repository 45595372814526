import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
    WalletDisconnectButton,
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';

import store from './store';
import { savePubkey, saveTab } from './baseReducer.js';

import { NFTs } from './NFTs'
import { FindNFTsButton } from './TXButton.js';

import { Layout, Menu, Breadcrumb } from 'antd';
import { Row, Col, Divider } from 'antd';
import { Button } from 'antd';
import { Table, Tag, Space } from 'antd';

import axios from 'axios';
import { saveLeaderboardData, resetLeaderboardData } from './baseReducer.js';

export function LeaderBoard() {
    const leaderboard_data = useSelector((state) => state.base.leaderboard_data)
    const leaderboardLoaded = useSelector((state) => state.base.leaderboardLoaded)

    useEffect(() => {
        //
        return () => {
            store.dispatch(resetLeaderboardData())
        }
    }, [])

    if (!leaderboardLoaded) {
        axios.get('https://swars.builtwithdark.com/leaderboard').then(res => {
            store.dispatch(saveLeaderboardData(res.data))
        })
    }

    const columns = [
        {
            title: 'Rank',
            dataIndex: 'rank',
            key: 'rank',
        },
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: 'Points this Season',
          dataIndex: 'pointsseason',
          key: 'pointsseason',
        },
        {
          title: 'Total Points',
          dataIndex: 'pointstotal',
          key: 'pointstotal',
        },
        {
            title: 'Prize',
            dataIndex: 'prize',
            key: 'prize',
          },
      ];

    const data = []
    let count = 0
    leaderboard_data.forEach(item => {
        count += 1
        data.push({
            rank: count,
            id: item.ID,
            pointsseason: item.PointsSeason,
            pointstotal: item.PointsTotal,
            prize: '-',
        })
    })

    if (leaderboardLoaded) {
        data[0].prize = '1 SOL'
        data[1].prize = '0.5% worth of SW token'
        data[2].prize = '0.25% worth of SW token'
        data[3].prize = '0.125% worth of SW token'
        data[4].prize = '0.125% worth of SW token'
    }

    return <div>
        <Table columns={columns} dataSource={data} />
    </div>
}
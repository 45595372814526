import { useSelector } from 'react-redux';

import {
    WalletDisconnectButton,
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';

import store from './store';
import { savePubkey, saveTab } from './baseReducer.js';

import { NFTs } from './NFTs'
import { FindNFTsButton } from './TXButton.js';

import { Layout, Menu, Breadcrumb } from 'antd';
import { Row, Col, Divider } from 'antd';
import { Button } from 'antd';
import { Table, Tag, Space } from 'antd';

import axios from 'axios';
import { fightDone, deleteResults, saveFightsData, setFighting, saveLeaderboardData } from './baseReducer.js';

import { LeaderBoard } from './Leaderboard'
import { LastFights } from './LastFights'

const { Header, Content, Footer } = Layout;

export function AppNew(props) {
    const changeTab = (e) => {
        store.dispatch(saveTab(e.key))
    }

    if (props.pubkey) {
        store.dispatch(savePubkey(props.pubkey.toString()))
    } else {
        store.dispatch(savePubkey(null))
    }

    return <Layout>
    <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
      <div className="logo" />
      <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
        <Menu.Item key="battle" onClick={changeTab}>Battle</Menu.Item>
        <Menu.Item key='lastFights' onClick={changeTab}>Last Fights</Menu.Item>
        <Menu.Item key="leaderboard" onClick={changeTab}>Leaderboard</Menu.Item>
      </Menu>
    </Header>
    <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
      </Breadcrumb>
      <AppCode />
    </Content>
    <Footer style={{ textAlign: 'center' }}>Solana Wars</Footer>
    <WalletConnector />
    <Discord />
  </Layout>
}

function WalletConnector() {
    let pubkey = useSelector((state) => state.base.pubkey)
    return <div className='walletButton'>{!pubkey ? <WalletMultiButton /> : <WalletDisconnectButton />}</div>
}

function Discord() {
    return <div className='discordButton'><a target='blank' href='https://discord.gg/cX6k7w4qPa'><img className='discordButtonImg' src='/discord.png' /></a></div>
}

function AppCode(props) {
    let pubkey = useSelector((state) => state.base.pubkey)
    
    let tab = useSelector((state) => state.base.tab)
    
    if (!pubkey) {
        return <LoggedOut />
    }

    if (tab == 'leaderboard') {
        return <LeaderBoard />
    }

    if (tab == 'battle') {
        return <Battle />
    }

    if (tab == 'lastFights') {
        return  <LastFights />
    }
}

function LoggedOut() {
    return <div className="App">
      <header className="App-header">
        <p>
          Welcome To SolanaWars
        </p>
        <p>The Universal NFT Autobattler</p>
        <p>Connect to Begin Fighting for your Project!</p>
      </header>
    </div>
}

function Battle() {
    const nfts = useSelector((state) => state.base.nftdata)
    const fight_done = useSelector((state) => state.base.fight_done)

    if (fight_done) {
        return <FightResults />
    }

    return <div>
        {!nfts.length ? <FindNFTsButton /> : <NFTs />}
    </div>
}

function FightResults() {
    const fightResults = useSelector((state) => state.base.fightResults)
    const pubkey = useSelector((state) => state.base.pubkey)
    const fighting = useSelector((state) => state.base.fighting)
    
    const battleFunction = () => {
        store.dispatch(setFighting())
        axios.post('https://swars.builtwithdark.com/fight_random', {
            pubkey: pubkey,
            image: fightResults.attacker_icon,
        }).then(res => {
            store.dispatch(fightDone(res.data))
        })
    }

    const delete_results = () => {
        store.dispatch(deleteResults())
    }

    return <div>
        <Divider orientation="left">Fight Results</Divider>
    <Row gutter={[16,24]}>
        <Col className="gutter-row" span={8}><ImageResultContainer image={fightResults.attacker_icon} winner={fightResults.winner == 'attacker'} /></Col>
        <Col className="gutter-row" span={8}><img className={'nft_image'} src={'./vs.jpeg'} /></Col>
        <Col className="gutter-row" span={8}><ImageResultContainer image={fightResults.defender_icon} winner={fightResults.winner == 'defender'} /></Col>
    </Row>
    <Row gutter={[16,24]}>
        <Button block type={!fighting ? 'primary' : ''} onClick={battleFunction}>Fight Again</Button>
    </Row>
    <Row gutter={[16,24]}>
        <Button block  onClick={delete_results}>Go Back</Button>
    </Row>
</div>
}

function ImageResultContainer(props) {
    return <Col>
        <img className={'nft_image'} src={props.image} />
        {props.winner ? <Button block>Winner (+80)</Button> : <Button danger block>Loser (+20)</Button>}
    </Col>
}
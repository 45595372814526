import { configureStore } from '@reduxjs/toolkit'

import baseReducer from './baseReducer.js'

const store = configureStore({
  reducer: {
    base: baseReducer,
  },
})

export default store